<!--购物车-->
<template>
    <div class="car">
        <topnav active="car"></topnav>
        <topmenu active="car"></topmenu>


        <div class="car_wrap public_width">
            
            <div class="cartMain" v-if="car_show">
                <h3 class="car_title">购物车</h3>
                <div class="cart_table">
                    <div class="cartMain_hd">
                        <ul class="order_lists d-flex cartTop">
                            <li class="list_chk d-center">
                                <!--所有商品全选-->
                                <input
                                type="checkbox"
                                v-model="isSelectAll"
                                @click="selectProduct(isSelectAll)"
                                />
                                全选
                            </li>
                            <li class="list_con">图片</li>
                            <li class="list_product">产品</li>
                            <li class="list_desc">参数描述</li>
                            <li class="list_price">价格（含13%税）</li>
                            <li class="list_amount">购买数量</li>
                            <li class="list_sum">小计</li>
                            <li class="list_op">操作</li>
                        </ul>
                    </div>

                    <div class="cartBox">
                        <div class="order_content">
                            <ul
                                class="order_lists"
                                v-for="(item, index) in car_list"
                                :key="index"
                            >
                                <li class="list_chk">
                                    <input
                                        type="checkbox"
                                        class="son_check"
                                        @click="item.active = !item.active"
                                        v-model="item.active"
                                        :disabled="item.store <= 0"
                                    />
                                </li>

                                <!-- 图片 -->
                                <li class="list_con" @click="to_detail(item)">
                                    <img :src="item.goods && item.goods.thumbs && item.goods.thumbs[0] ? item.goods.thumbs[0]:''" alt="" />
                                </li>

                                <li class="list_product">

                                  <!-- 产品名称 -->
                                  <div class="list_name" @click="to_detail(item)">{{ item.goods && item.goods.name ? item.goods.name:'' }}</div>
                                  <!-- 规格型号 -->
                                  <div class="list_item"><span class="title">规格型号</span><span class="val">{{item.goods && item.goods.standard ? item.goods.standard:''}}</span></div>
                                  <!--封装 -->
                                  <div class="list_item"><span class="title">封装</span><span class="val">{{item.goods && item.goods.package ? item.goods.package:''}}</span></div>
                                </li>

                                <!-- 参数描述 -->
                                <li class="list_desc">
                                    <el-popover
                                    popper-class="pb_pop"
                                    placement="bottom-start"
                                    :width="200"
                                    trigger="hover">
                                        <template v-slot:reference> 
                                            <div class="text-seven">
                                                <div v-if="item.goods.filters && item.goods.filters.length > 0">
                                                    <p v-for="(v) in item.goods.filters" :key="(v)">{{v.filter_name}} : {{v.value}}</p>
                                                </div>
                                                <p v-else>{{ item.goods.desc }}</p>
                                            </div>	
                                        </template>
                                        <div v-if="item.goods.filters && item.goods.filters.length > 0">
                                            <p v-for="(v) in item.goods.filters" :key="(v)">{{v.filter_name}} : {{v.value}}</p>
                                        </div>
                                        <p v-else>{{ item.goods.desc }}</p>
                                    </el-popover>     
                                </li>

                                <!-- 单价 -->
                                <li class="list_price">
                                    <div class="price_box">
                                        <p v-for="(v, k) in item.goods.price_ladder" :key="v">{{k}}+/￥{{v}}</p>
                                    </div>
                                </li>
                                
                                <!-- 购买数量 -->
                                <li class="list_amount">
                                    <div class="amount_wrap">
                                        <div class="amount_box d-flex">
                                            <el-button
                                            class="reduce"
                                            @click="sub(item)"
                                            :disabled="item.goods.stock <= 0"
                                            >-</el-button
                                            >

                                            <el-input
                                            type="number"
                                            class="sum"
                                            v-model="item.num"
                                            @blur="num_blur($event, item)"
                                            :disabled="item.goods.stock <= 0"
                                            />

                                            <el-button
                                            class="plus"
                                            @click="add(item)"
                                            :disabled="item.goods.stock <= 0"
                                            >+</el-button
                                            >
                                        </div>

                                        <div class="inventory_count">
                                            <p>库存 {{ item.goods.stock }}个</p>
                                            <p class="limit" v-if="item.goods.purchase_limit">单人限购 <span class="num">{{item.goods.purchase_limit}}</span></p>
                                        </div>
                                    </div>
                                </li>

                                <!-- 总金额 -->
                                <li class="list_sum d-cetner">
                                    <p class="sum_price">￥{{ item.total || 0 }}</p>
                                </li>

                                <!-- 操作 -->
                                <li class="list_op">
                                    <p class="del">
                                        <a
                                        href="javascript:;"
                                        class="delBtn"
                                        @click="deleteOneProduct(index, item.id)"
                                        >删除</a
                                        >
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--底部-->
                <div class="bar-wrapper">
                    <div class="menu_btns d-flex">
                        <div class="list_chk d-flex">
                            <!--所有商品全选-->
                            <input type="checkbox" v-model="isSelectAll"  @click="selectProduct(isSelectAll)"/>
                            全选
                        </div>

                        <div class="batch_del" @click="deleteProduct()">
                            <i class="icon iconfont iconE-shanchu"></i>
                            批量删除
                        </div>
                    </div>

                    <div class="bar_bottom d-flex">
                        <div></div>
                        <div class="bar-right d-flex">
                            <div class="piece d-flex">
                                已选择
                                <span class="piece_num"> {{ getTotal.totalNum || 0 }} </span>
                                个商品（不包邮 ）
                            </div>
                            <div class="totalMoney">
                                总金额: <span class="red_txt">￥</span>
                                <span class="total_text">{{ getTotal.totalPrice  || 0}} </span>
                            </div>
                            <div class="calBtn">
                                <el-button
                                :class="{ btn_sty: getTotal.totalPrice > 0 }"
                                @click="get_pay"
                                :disabled="getTotal.totalPrice <= 0"
                                >
                                去结算
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bgc_empty" v-else>
                <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132046.png" alt=""/>
                <div>购物车空空如也~</div>
            </div>
        </div>

        <foot />
        <toolbar />
    </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";
import { getData, postData } from "@/api/user";
import { computed, toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from 'element-plus'
import { debounce } from "@/libs/util";


export default {
    name: "car",
    setup() {
        const store = useStore()
        const router = useRouter()
        store.commit('setCount'); 

        let state = reactive({
            userid: store.state.userInfo.id,
            car_list: [],
            car_show: true,
        });

        // 检测是否全选
        let isSelectAll = computed(() => {
            let len = state.car_list.length;
            let num = 0; // 选中的 个数
            let disabled_num = 0; //  库存为0的 个数
            let isAll = false;

            state.car_list.forEach((val) => {
                if (val.goods.stock <= 0) {
                    disabled_num++;
                }

                if (val.active) {
                    num++;
                    if (num == len - disabled_num) {
                        isAll = true;
                    }
                }
            });

            return isAll;
        })

        // 获取总价和产品总数
        let getTotal = computed(() => {
            // 获取list中active为true的数据
            var proList = state.car_list.filter(function (val) {
                return val.active;
            }),
            totalPrice = 0;

            proList.forEach((e) => {
                if (e.goods.stock > 0) {
                    if (e.active) {
                        totalPrice += Number(e.total);
                    }
                }
            });

            // 选择产品的件数就是proList.lenth，总价就是totalPrice
            return { totalNum: proList.length, totalPrice: totalPrice.toFixed(2) };
        })

        // 选中产品id
        let goods_ids = computed(() => {
            let ids = [];
            state.car_list.forEach(function (val) {
                if (val.active) {
                    ids.push(val.id);
                }
            });
            return ids;
        })

        let shopping_car = () => {
            let url = `api/shopping_car/index`;
            getData({
                url: url,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.car_list = data.data.records;

                    if (state.car_list.length > 0) {
                        state.car_list.map( (item) => {
                            item['active'] = item.goods.stock > 0 ? true : false 
                            change_num(item);
                        })
                        state.car_show = true
                    } else {
                        state.car_show = false
                    }
                }
            });
        }

        let init = () => {
            if (state.userid) {
                debounce(shopping_car())
            } else {
                router.push({ path: "/" });
                window.scrollTo(100,0)
            }
            
        }
        init()

        // 全选与取消全选
        let selectProduct = (_isActive) => {
            //遍历car_list,全部取反
            let arr = state.car_list;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].goods.stock > 0) {
                    arr[i].active = !_isActive;
                }
            }
        }

        // 减 -
        let sub = (v) => {
            let d = v.goods
            if (v.num >= d.min_num *2) {
                v.num-= d.min_num;
                debounce(change_num(v))
                debounce(store_num(v))
            }
        }

        // 加 +
        let add = (v) => {
            let d = v.goods
            let limit = d.purchase_limit && d.purchase_limit > 0 ? (d.stock > d.purchase_limit ? d.purchase_limit : d.stock) : d.stock
            if (v.num <= Number(limit) - d.min_num) {
                v.num += d.min_num;
                debounce(change_num(v)) 
                debounce(store_num(v))
            }
        }

        // 输入
        let num_blur = (e, v) => {
          let d = v.goods
          let limit = d.purchase_limit && d.purchase_limit > 0 ? (d.stock > d.purchase_limit ? d.purchase_limit : d.stock) : d.stock
          let val = Number(e.currentTarget.value.trim());
          if (val > limit) {
              v.num = d.min_num*Math.floor(limit/d.min_num) //3*6
              ElMessage.warning(`最大订购量为${v.num}个，已为您修正。`)
          }else if(val < 1 && d.stock > 0 || val< d.min_num) {
              v.num = d.min_num;
              ElMessage.warning(`最小起订量为${v.num}个，已为您修正。`)
          }else if (d.stock <= 0) {
              v.num = '';
          }else {
            let ge_max = d.min_num*Math.ceil(val/d.min_num) // 3*7
            let ge_min = d.min_num*Math.floor(val/d.min_num) //  3*6
            v.num = ge_max < limit ? ge_max : ge_min 
            if(val % d.min_num !== 0) {
              ElMessage.warning(`必须以${d.min_num}的倍数修改，已为您修正。`)
            }
          }
          debounce(change_num(v))
          debounce(store_num(v))
        }

        // 购买数量改变
        let change_num = (v) => {
            let url = `api/shopping_car/set_num`;
            postData({
                url: url,
                data: {
                    goods: v.goods_id,
                    num: v.num,
                },
            }).then((res) => {
                let data = res.data
                if( data.code == 200) {
                    v.total = data.data.money
                }else {
                    v.total = 0
                }
            });
        }

		let store_num = (v) => {
			postData({
                url: `api/shopping_car/store_num/${v.id}`,
                data: {
                    goods_id: v.goods_id,
                    num:v.num
                },
                }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    // ElMessage.success(data.data.msg);
                }else if (data.code === 422) {
                    ElMessage.error(data.data.msg);
                }else {
                    ElMessage.error(data.data.msg);
                }
            });
		}

        //删除选中的产品
        let deleteProduct = () => {
            if (goods_ids.value.length <= 0) return;

            ElMessageBox.confirm('确定删除此商品吗, 是否继续?', '', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = `api/shopping_car/delete`;
                postData({
                    url: url,
                    data: {
                        shopping_car_id_ary: goods_ids.value,
                    },
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        ElMessage.success(data.data.msg);
                        shopping_car()
						store.commit('setCount');
                    } else if (data.code === 422) {
                        ElMessage.error(data.data.msg);
                    } else {
                        ElMessage.error(data.data.msg);
                    }
                });
            })
        }

        //删除单条产品
        let deleteOneProduct = (index, id) => {
            ElMessageBox.confirm('确定删除此商品吗, 是否继续?', '', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = `api/shopping_car/delete`;
                postData({
                    url: url,
                    data: {
                        shopping_car_id_ary: [id],
                    },
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        ElMessage.success(data.data.msg);
                        shopping_car()
						store.commit('setCount');
                    } else if (data.code === 422) {
                        ElMessage.error(data.data.msg);
                    } else {
                        ElMessage.error(data.data.msg);
                    }
                });
            })
        }

        // 去支付
        let get_pay = () => {
            router.push({
                name: "pay",
                query: {
                    shopping_car_id_ary: JSON.stringify(goods_ids.value),
                },
            });
            window.scrollTo(100,0)

        }

        let to_detail = (v) => {
            router.push({
                path: '/detail',
                query: {
                    id: v.goods_id
                }
            })
            window.scrollTo(100,0)

        }

        return {
            isSelectAll,
            getTotal,
            goods_ids,
            ...toRefs(state),
            get_pay,
            selectProduct,
            add,
            sub,
            num_blur,
            deleteProduct,
            deleteOneProduct,
            to_detail,
			store_num
        }
    },
    components: {
        topnav,
        topmenu,
        foot,
        toolbar
    }
};
</script>
<style lang="less" scoped>
input[type="checkbox"] {
    margin: 0 12px 0 0;
    cursor: pointer;
    position: relative;
    width: 15px!important;
    height: 15px!important;

    font-size: 14px;
}

input[type="checkbox"]::after {
    position: absolute;
    top: 0;
    color: #fff;
    background-color: #fff;
    width: 17px;
    height: 17px;
    display: inline-block;
    visibility: visible;
    text-align: center;
    content: " ";
    border: 1px solid #d1d1d1;
}

input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 12px;
    font-weight: bold;
    background-color: #1C46B7;
}
.car {
    padding: 154px 0 440px;
    background-color: #fff;
    .car_wrap {
        padding: 40px 0;
    }

    .car_title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #333;
        margin-bottom: 30px;
    }


    .cartMain {
        position: relative;
        width: 100%;
        margin: 0 auto;

        .cart_table {
            margin-bottom: 20px;
            border: 1px solid #eaeaea;

            .cartMain_hd {
                width: 100%;
                height: 32px;
                line-height: 32px;
                background: #f5f5f5;
                li {
                    font-size: 12px;
                    color: #666!important;
                    white-space: nowrap;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                }

                .order_lists {
                    padding: 0;
                }

                .cartTop {
                    height: 32px;

                    .list_chk {
                        width: 70px;
                        justify-content: flex-start;
                        margin-top: 0;
                    }
                }
            }

            .order_lists {
                display: flex;
                align-items: flex-start2;
                width: 100%;
                padding: 20px 0 15px 0;
                border-bottom: 1px solid #eaeaea;
                overflow: hidden;
                li {
                    padding: 0 10px;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    color: #333;
                    line-height: 18px;
                }

                .list_chk {
                    width: 70px;
                    justify-content: flex-start;
                    margin-top: 30px
                }

                /* 图片 */
                .list_con {
                    align-items: center;
                    width: 140px;
                    text-align: center;
                    cursor:pointer;
                    
                    img {
                        display:inline-block;
                        width: 80px;
                        height: 80px;
                        border: 1px solid #eaeaea;
                        object-fit: contain;
                        flex-shrink: 0;
                    }
                }

                .list_product {
                  width: 250px;
                  justify-content: flex-start; 
                }
                
                /* 产品名称*/
                .list_name {
                    width: 180px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    cursor:pointer;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .list_name:hover {
                  color:#3385F6;
                }

                .list_item {
                  margin-bottom: 5px;
                  .title {
                    display: inline-block;
                    width: 60px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #666666;
                  }
                  .val {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #333333;
                  }
                }

                /* 参数描述 */
                .list_desc {
                    width: 250px;
                    justify-content: flex-start;
                    cursor: pointer;
                }

                /* 单价 */
                .list_price {
                    width: 170px;
                    justify-content: flex-start;
                }

                /* 购买数量 */
                .list_amount {
                    width: 200px;

                    
                    .amount_wrap {
                        padding:0 40px;
                    }

                    .amount_box {
                        height: 30px;
                        position: relative;
                        margin-bottom: 8px;

                        /deep/ .el-input {
                            width: 46px;
                            height: 30px;
                            line-height: 30px;
                            color: #333;
                            text-align: center;
                            background-color: #fff;
                            z-index: 2;
                        }

                        /deep/ .el-input__inner {
                            padding: 0 3px;
                            width: 46px;
                            height: 30px;
                            line-height: 30px;
                            border: 1px solid #aaa;
                            color: #343434;
                            text-align: center;
                            background-color: #fff;
                            z-index: 2;
                            border-radius: 0;
                        }

                        /deep/ .el-button {
                            padding: 0;
                            width: 30px;
                            height: 30px;
                            min-height: 30px;
                            border: 1px solid #aaa;
                            background: #f0f0f0;
                            text-align: center;
                            color: #444;
                            border-radius: 0;
                        }

                        .reSty {
                            color: #cbcbcb;
                            pointer-events: none;
                        }

                        .reSty:hover {
                            color: #cbcbcb;
                        }

                        .reduce {
                            border-right-color: transparent;
                        }

                        .plus {
                            border-left-color: transparent;
                        }
                    }

                    .inventory_count {
                        font-size: 12px;
                        line-height: 1;
                        color: #998;
                        .limit {
                          margin-top: 5px;
                          color:#3385F6;
                          font-weight: 600;
                        }
                    }
                }

                /* 总金额 */
                .list_sum {
                    width: 120px;
                    text-align: center;

                    .sum_price {
                        font-size: 13px;
                        color: #FF7F23;
                        font-weight: bold;
                        line-height: 1;
                    }
                }

                /* 操作 */
                .list_op {
                    width: 120px;
                    text-align: center;
                    white-space: nowrap;

                    .del {
                        a {
                            color: #666666;
                        }
                    }
                }
            }

            .order_lists:last-child {
                border-bottom: none;
            }
        }
    }

    .bar-wrapper {
        padding: 30px 15px 20px 0;
        width: 100%;
        border: 1px solid #eaeaea;
        font-size: 13px;
        color: #666666;

        .menu_btns {
            margin-bottom: 20px;
            align-items: center;

            .list_chk {
                padding: 0 0 0 10px;
            }

            .batch_del {
                margin-left: 20px;
                cursor: pointer;
            }
        }

        .bar_bottom {
            justify-content: space-between;

            .bar-right {
                color: #3c3c3c;
                align-items: center;

                .piece {
                    margin-right: 10px;
                    font-size: 13px;
                    color: #666666;
                    span {
                        display: inline-block;
                        padding: 0 5px;
                        color: #3385F6;
                    }
                }

                .totalMoney {
                    font-size: 13px;
                    color: #666666;

                    .total_text,
                    .red_txt {
                        font-size: 15px;
                        font-weight: 700;
                        color: #FF7F23;
                    }
                }

                .calBtn {
                    margin-left: 30px;

                    .el-button {
                        width: 128px;
                        height: 42px;
                        font-size: 16px;
                        color: #fff;
                        text-align: center;
                        background: #b0b0b0;
                    }

                    .btn_sty {
                        background: #1C46B7;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .bgc_empty {
        padding:167px 0 289px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        text-align: center;
        background: #F5F5F5;

        img {
            width: 106px;
            height: 130px;
            margin-bottom: 24px;
        }

        
    }
}

</style>

<style>
.el-popper.is-light.pb_pop {
  font-size: 12px!important;
}
</style>


